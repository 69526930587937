import AddIcon from '@mui/icons-material/Add'
import { Stack } from '@mui/material'
import useRouteTracker from 'hooks/useRouteTracker'
import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Innermap } from '../../api/internalAgreement/types'
import { useRemarkAccessQuery } from '../../api/remarks'
import CustomEditIcon from '../../assets/icons/CustomEditIcon'
import EmptyPage from '../../components/EmptyPage'
import { ButtonData, EmptyPageData } from '../../components/EmptyPage/EmptyPage.types'
import { uiContext } from '../../contexts/ui'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import useQuery from '../../hooks/useQuery'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import { selectedMenuViewSelector } from '../../store/slices/documentsPages/menu'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
  setSelectedProjectPhase,
} from '../../store/slices/documentsPages/projectInfo'
import {
  currentDocumentSelector,
  docsSelector,
  isCmnTomSelector,
  tomSelector,
} from '../../store/slices/documentsPages/tom'
import {
  TableFormValues,
  currentSchemaSelector,
  setCurrentSchema,
  setInitialSchemaCompanyFromMenu,
  setIsOpenFormAddMember,
  setSchemaFormMode,
  setTableFormValues,
} from '../../store/slices/internalAgreement'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { ProjectPhaseEn, projectPhaseEnToRu } from '../../types/project'
import { determineTomType, TomDocument } from '../../types/tom'
import { getEmptyPageData } from '../Home'
import { InternalAgreementSchemes } from '../Schemes/InternalAgreementSchemes'
import { AttachmentsExportDrawer } from './components/AttachmentsExportDrawer'
import { AttachmentsPage } from './components/AttachmentsPage'
import { DocumentAccessDrawer } from './components/DocViewNavBarContentInfo/DocumentAccessDrawer'
import { DocViewRightSideBar } from './components/DocViewRightSideBar'
import { FormPocketDrawer } from './components/FormPocketDrawer'
import { InternalAgreementDrawer } from './components/InternalAgreementDrawer'
//import PdfViewer from './components/PdfViewer'
import { RemarkFormDrawer } from './components/RemarkFormDrawer'
import { CMN_TOM_TITLE } from './types'
import { TestPDFViewer } from './components/TestPDFViewer'
import { PragmaPdfViewer } from './components/TestPDFViewer/PragmaPdfViewer'
import { setRemarkFormData } from '@store/slices/remarks/remarks'
import { RemarkFormData } from '@store/slices/remarks/remarks.types'
import { Test } from './components/TestPDFViewer/Test'

const getTitleName = (currentDocument: TomDocument) => {
  return currentDocument?.change === null
    ? `Версия ${currentDocument?.version}`
    : `Изм. ${currentDocument?.change}`
}

const DocView: React.FC = () => {
  const dispatch = useAppDispatch()
  const cUI = useContext(uiContext)
  const navigate = useNavigate()
  const queryHandler = useQuery()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const presetPhase: ProjectPhaseEn | null = queryHandler.get('phase')

  const { project, userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled: boolean = !!tom?.features.annulment.annulled
  const { tomVersions, tomChanges } = useTypedSelector(docsSelector)
  const { selectedMenuView } = useTypedSelector(selectedMenuViewSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)

  const { data: remarkAcces } = useRemarkAccessQuery(
    {
      projectId,
      tomId: tom?.id!,
      type: determineTomType(selectedProjectPhase),
    },
    { skip: !tom },
  )

  const pdfLink =
    tomVersions?.length || tomChanges?.length
      ? !tomVersions?.length
        ? tomChanges[0]?.doc?.link
        : tomVersions[0]?.link
      : ''

  useRouteTracker({ record: true })

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  useBreadcrumbs(
    [{ title: project?.shifrName!, url: './toms' }, isCmnTom ? { title: CMN_TOM_TITLE } : { title: tom?.title! }],
    [project, tom, isCmnTom, currentSchema],
  )

  const getEmptyPageDataButtons = (): ButtonData[] => {
    if (isAnnulled) return []
    // @ts-ignore
    const data: ButtonData[] = [
      tom?.status === 'Согласовано' &&
      tom.features.changeMode && {
        text: 'Внести изменения',
        icon: CustomEditIcon,
        onClick: () => dispatch(setOpenedDrawer({ openedDrawer: 'docChange' })),
      },
      tom?.status !== 'Согласовано' && {
        text: 'Добавить версию',
        icon: AddIcon,
        onClick: () => dispatch(setOpenedDrawer({ openedDrawer: 'version' })),
      },
    ].filter((v) => !!v)

    return data
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Для тома не было загружено ни одной версии документа. <br />
      Нажмите добавить версию для загрузки файла.
    </>,
    getEmptyPageDataButtons(),
  )

  const emptyPageDataWithoutControls: EmptyPageData = getEmptyPageData(
    <>Для тома не было загружено ни одной версии документа.</>,
  )

  const displayCreateRemarkButton = useMemo(() => {
    const access = remarkAcces?.access
    const isLatestVersion = currentDocument.version === null
      ? true
      : tomVersions.length > 0
        ? currentDocument.version === tomVersions[0].version
        : false

    return !isAnnulled && isLatestVersion && access
  }, [currentDocument.version, isAnnulled, remarkAcces?.access, tomVersions])

  const createRemark = (page: number, numPages: number) => {
    dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          page,
          pages: numPages,
          version: currentDocument.version,
          change: currentDocument.change,
        } as RemarkFormData,
      }),
    )
  }

  const getContent = () => {
    switch (selectedMenuView) {
      case 'info':
      case 'versions':
      case 'changes':
      case 'remarks':
        return (
          <Stack direction='row' spacing={2.5} style={{ overflow: 'hidden' }} flex={1} mt={1}>
            {pdfLink && Object.keys(currentDocument).length ? (
              //<Test
              //  file={currentDocument.link}
              //  actionButtonRemark={displayCreateRemarkButton ? createRemark : undefined}
              //  fileName={getTitleName(currentDocument)} />
              <PragmaPdfViewer
                file={currentDocument.link}
                actionButtonRemark={displayCreateRemarkButton ? createRemark : undefined}
                fileName={getTitleName(currentDocument)} />
              //<TestPDFViewer file={pdfLink}/>
              //<PDFDoc file={pdfLink}/>
              //<PdfViewer remarkAcces={remarkAcces?.access} />
            ) : (
              <EmptyPage
                data={userWithLessControls ? emptyPageDataWithoutControls : emptyPageData}
                fullPage
                wrapperProps={{ style: { marginTop: 0 } }}
              />
            )}

            <DocViewRightSideBar
              hasNoDocument={!pdfLink}
              tomVersions={tomVersions}
              tomId={tom?.id}
              type={determineTomType(selectedProjectPhase)}
            />
          </Stack>
        )

      case 'attachments':
        return (
          <>
            <AttachmentsPage />
            <AttachmentsExportDrawer />
          </>
        )
    }
  }

  const closeDrawer = (dirty: boolean) => {
    if (dirty) {
      openConfirm()
    } else onInternalAgreementDrawerClose(true)
  }

  const onInternalAgreementDrawerClose = (confirm: boolean) => {
    if (confirm) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
      dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
      dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: '' }))
      dispatch(onDrawerClose({ dirty: false }))
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: onInternalAgreementDrawerClose,
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const immediatelyDrawerClose = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))
  }

  useEffect(() => {
    if (!tom) {
      navigate(`/project/${projectId}/toms`, { replace: true })
    }
  }, [tom])

  useEffect(() => {
    if (presetPhase) {
      dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseEnToRu[presetPhase] }))
    }
  }, [presetPhase])

  return (
    <>
      {currentSchema?.id && selectedMenuView === 'remarks' ? (
        <InternalAgreementSchemes />
      ) : (
        <>
          {getContent()}
        </>
      )}

      <FormPocketDrawer
        open={openedDrawer === 'formPocket'}
        onDrawerClose={immediatelyDrawerClose}
        initialPhase={selectedProjectPhase}
      />
      <InternalAgreementDrawer
        open={openedDrawer === 'internalAgreement'}
        closeDrawer={closeDrawer}
        tomId={tom?.id}
        type={determineTomType(selectedProjectPhase)}
      />
      <DocumentAccessDrawer
        open={openedDrawer === 'docAccess'}
        closeDrawer={immediatelyDrawerClose}
        tomId={tom?.id}
        type={determineTomType(selectedProjectPhase)}
      />
      <RemarkFormDrawer
        open={openedDrawer === 'remarks'}
        closeDrawer={immediatelyDrawerClose}
        tom={tom}
        type={determineTomType(selectedProjectPhase)}
      />
      <ConfirmDialog />
    </>
  )
}

export default DocView
